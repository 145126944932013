import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'
import Share from 'components/elements/Share'

// Context
import { VacancyContext } from 'templates/vacancy'

// Components
import Form from 'components/related/Form'

const Block = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  border-radius: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
`

const CtaImage = styled(Image)`
  width: 150px;
`

const CtaDescription = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text.purple};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${({ theme }) => theme.font.size.l};
  }
`

const Disclaimer = styled(ParseContent)`
  font-size: ${({ theme }) => theme.font.size.s};
  color: ${({ theme }) => theme.color.text.purple};

  & p {
    line-height: ${({ theme }) => theme.font.size.l};
  }
`

interface VacancyFormProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_VacancyForm
  location: any
}

const VacancyForm: React.FC<VacancyFormProps> = ({ fields, location }) => {
  const vacancyContext = React.useContext(VacancyContext)

  return (
    <section className="color-background-background" id="solliciteer">
      <div className="container py-5">
        <ParseContent content={fields?.description} />

        <div className="my-4 my-lg-5 row justify-content-center">
          <div className="col-lg-10">
            <Block className="p-4 p-lg-5">
              <div className="row">
                <div className="col-sm-7 d-flex align-items-center">
                  {fields?.formid && (
                    <Form
                      className="w-100"
                      id={fields?.formid}
                      defaultFields={{ input_7: vacancyContext.title }}
                      privacyUrl="/privacyverklaring"
                      privacyUrlPlacement="privacybeleid"
                      scrollToConfirm={false}
                    />
                  )}
                </div>
                <div className="col-sm-5 justify-content-center d-flex mt-4 mt-sm-0">
                  <div className="d-flex align-items-center w-100 flex-column justify-content-between">
                    <div className="text-center">
                      <CtaImage className="mx-auto" image={fields.cta?.image} />
                      <CtaDescription
                        className="mt-3"
                        content={fields.cta?.description}
                      />
                    </div>

                    <div className="mt-4">
                      <div className="color-contrast font-size-s text-sm-left text-center">
                        {fields.sharedescription}
                      </div>
                      <Share
                        className="d-flex mt-1"
                        location={location.href}
                        title={vacancyContext.title}
                        type="horizontal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Block>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-8">
            <Disclaimer content={fields?.disclaimer} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default VacancyForm
